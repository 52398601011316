// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgUnderline = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.75 5a.75.75 0 0 0-1.5 0v6a4.75 4.75 0 1 0 9.5 0V5a.75.75 0 0 0-1.5 0v6a3.25 3.25 0 0 1-6.5 0zM6 18.25a.75.75 0 0 0 0 1.5h12a.75.75 0 0 0 0-1.5z"
      clipRule="evenodd"
    />
  </svg>
);
export const UnderlineIcon = forwardRef(SvgUnderline);
