// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgBold = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.25 5.6c0-.746.604-1.35 1.35-1.35H12c.038 0 1.012 0 2.002.496a3.7 3.7 0 0 1 1.435 1.244c.403.605.646 1.379.646 2.343 0 .965-.243 1.738-.646 2.343-.25.375-.55.668-.862.897.501.267 1.007.65 1.407 1.2.475.654.768 1.498.768 2.56s-.293 1.907-.768 2.561c-.47.646-1.086 1.062-1.67 1.33a6 6 0 0 1-1.574.46 6 6 0 0 1-.715.066H12L12 19v.75H8.6a1.35 1.35 0 0 1-1.35-1.35zM12 12.417h.022q.033 0 .1.005c.092.006.226.018.39.043.33.05.758.15 1.175.342.417.19.801.462 1.081.848.275.377.482.907.482 1.678 0 .772-.207 1.302-.482 1.679-.28.385-.664.657-1.08.848a4.5 4.5 0 0 1-1.176.341 5 5 0 0 1-.49.049H8.75v-5.833h3.248m-3.248-1.5h3.262l.078-.005a3.35 3.35 0 0 0 1.242-.333c.327-.164.632-.398.857-.735.221-.332.394-.809.394-1.51 0-.703-.173-1.18-.395-1.512a2.2 2.2 0 0 0-.857-.735C12.673 5.758 12 5.75 12 5.75H8.75z"
      clipRule="evenodd"
    />
  </svg>
);
export const BoldIcon = forwardRef(SvgBold);
