// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgCode = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.702 5.278a.75.75 0 0 1 .52.924l-3.5 12.5a.75.75 0 1 1-1.444-.404l3.5-12.5a.75.75 0 0 1 .924-.52M2.47 12.53a.75.75 0 0 1 0-1.06l3.5-3.5a.75.75 0 0 1 1.06 1.06L4.06 12l2.97 2.97a.75.75 0 1 1-1.06 1.06zm15.56-4.56 3.5 3.5a.75.75 0 0 1 0 1.06l-3.5 3.5a.75.75 0 1 1-1.06-1.06L19.94 12l-2.97-2.97a.75.75 0 0 1 1.06-1.06"
      clipRule="evenodd"
    />
  </svg>
);
export const CodeIcon = forwardRef(SvgCode);
