// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgStrikethrough = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.852 15.356a3.318 3.318 0 0 1-2.92 4.894H6a.75.75 0 0 0 0 1.5h7.932a4.818 4.818 0 0 0 4.24-7.106.75.75 0 0 0-1.32.712M16.286 3.75a.75.75 0 0 0 0-1.5h-6.218a4.818 4.818 0 0 0-2.393 9H3a.75.75 0 0 0 0 1.5h18a.75.75 0 0 0 0-1.5h-8.878l-3.103-1.034a3.318 3.318 0 0 1 1.049-6.466z"
      clipRule="evenodd"
    />
  </svg>
);
export const StrikethroughIcon = forwardRef(SvgStrikethrough);
